<template>
  <div class="download3">
      <div class="logo"><img src="@/assets/download3-logo.png" alt=""></div>
      <div class="download-btn" @click="getMobileValue()"></div>
      <div class="download-app"><img src="@/assets/download3-app.png" alt=""></div>
      <div class="bg" v-if="isShow"><img src="@/assets/yd.png" alt=""></div>
    </div>
</template>

<script>
export default {
  name: "download3",
  data() {
    return {
      isShow:false,
    };
  },
  methods: {
      getMobileValue() {
          var ua = navigator.userAgent.toLowerCase();
          if (ua.match(/MicroMessenger/i) == "micromessenger") {
              this.isShow = true
              return false;
          } else {
              this.isShow = false
          }
          let u = navigator.userAgent;
          let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
          let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
          if (isAndroid) {
              window.location.href = 'https://mobile.baidu.com/item?pid=5000012776&source=appbaidu'
          } else if (isIOS) {
              window.location.href = 'https://apps.apple.com/cn/app/%E8%93%9D%E7%91%9F%E5%88%B0%E5%AE%B6/id1641069472'
          }
      },
  }
};
</script>
<style lang="less" scoped>
 .download3 {
    width:100%;
    min-height: 100vh;
    background: url('../../assets/download3-bg.png') #FEEEEE no-repeat top;
    background-size: 100% auto;
    display: inline-block;
     .logo{
        width:100%;
         text-align: center;
         display: flex;align-items: center;justify-content: center;
         margin-top: 39px;
         img{
            width:300px;height:auto
         }
     }
    .download-btn{
        width:100%;
        height: 133px;
        background-image: url('../../assets/download3-btn.png');
        background-position: center center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
     .download-app{
         width:300px;
         margin: auto;
         img{
             width:100%;
         }
     }
    .bg {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.9);
        text-align: end;
        overflow: hidden;
        padding: 1rem 5px 0 1rem;
    }

    .bg img {
        width: 93%;
        height: auto;
        position: fixed;
        z-index: 10;
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
    }
}
</style>
